import React from "react"

import Layout from "../components/layout"

import SEO from "../components/seo"
import "../css/pages/alwaysapril.scss"

const AlwaysApril = () => {
  let language = "en"

  const CLIENT_NAME = "Oral Surgery Specialists of Oklahoma"
  const CALENDLY_LINK = ""
  const YOUTUBE = "j40shrSVCCQ"

  return (
    <Layout pageTitle="procedure-page" language={language}>
      <SEO
        lang="en"
        title="Always April"
        description={
          "During Oral Cancer Awareness Month, " +
          CLIENT_NAME +
          " is providing free screenings to members of our community."
        }
      />
      {/* <Button
          buttonText={language === "es" ? "ATRÁS" : "BACK"}
          goBack
          /> */}

      <div className="joshua-tree-content">
        <div className="alwaysapril">
          <div className="subpage april-img">
            <img src="/OKC/DEV/always-april-logo.svg" />
          </div>
          <div className="april-grid">
            <div className="april-text">
              <p>
                April is Oral Cancer Awareness Month. Always April, a national
                partnership of oral surgery practices across the country,
                strives to bring oral cancer awareness into a year-round
                discussion. At Oral Surgery Specialists of Oklahoma, we know the
                importance of early detection, and we actively look for signs of
                oral cancer during patient visits.
              </p>
              <h2>The facts...</h2>
              <ul className="color-bullet">
                <li>Oral cancer is rising in low-risk groups.</li>
                <li>
                  Oral cancer kills about as many Americans as melanoma and
                  twice as many as cervical cancer.
                </li>
                <li>
                  Oral cancer is rising in women, young people, and non-smokers.
                </li>
                <li>
                  At least 25% of oral cancer victims have no known risk
                  factors.
                </li>
                <li>
                  Oral cancer is a global challenge, with more than 657,000
                  cases and nearly 330,000 deaths each year.
                </li>
              </ul>
            </div>
            <div className="april-info">
              <h2>
                Thank you for your interest in Always April. Our free oral
                cancer screening event is over.{" "}
              </h2>

              <span>
                It is important to be aware of changes that happen in your mouth
                and continue routine dental visits and cleanings. If you notice
                any change in your oral health, please contact your oral health
                provider for an examination.
              </span>

              {CALENDLY_LINK && (
                <a
                  className="schedule"
                  href={CALENDLY_LINK}
                  target="_blank"
                  rel="noreferrer">
                  SCHEDULE APPOINTMENT
                </a>
              )}
            </div>
          </div>
        </div>

        {YOUTUBE && (
          <>
            <div className="april-yt-wrapper">
              <iframe
                src={`https://www.youtube.com/embed/${YOUTUBE}`}
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                title="video"
                className="april-yt"
              />
            </div>

            <div style={{ height: "60px" }}></div>
          </>
        )}

        {CALENDLY_LINK && (
          <span className="disclaimer">
            *Advanced appointments requested. Local health guidelines for
            COVID-19 will be followed and masks are required as we look to
            provide a safe and healthy environment for you, our patients, staff,
            and&nbsp;doctors.
          </span>
        )}
      </div>
    </Layout>
  )
}

export default AlwaysApril
